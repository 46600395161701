import React from "react";

const ButtonOutline = ({ children, href, onClick, className }) => {
  const handleClick = () => {
    if (href === undefined) {
      if(onClick) onClick();
      return;
    }
    window.location.href = href; // Redirect the user to the specified URL
  };
  return (
    <button
      onClick={handleClick}
      className={"font-medium tracking-wide py-2 px-5 sm:px-8 border border-orange-500 text-orange-500 bg-white-500 outline-none rounded-l-full rounded-r-full capitalize hover:bg-orange-500 hover:text-white-500 transition-all hover:shadow-orange "+className}
    >
      {" "}
      {children}
    </button>
  );
};

export default ButtonOutline;
