import React, { useState, useEffect } from "react";
import ButtonOutline from "./misc/ButtonOutline";
import settings from "../settings.json";
import { UUIDTypes, v4 } from "uuid";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [anonId, setAnonId] = useState();

  const isEmailValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex

    if (email && emailRegex.test(email)) {
      setEmailError("");
      return true;
    }

    setEmailError("Ongeldige email");
    return false;
  };

  const postAnswersToBackend = async () => {
    if (isEmailValid()) {
      const response = await fetch(settings.backend_url + "newsletter-form", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: anonId,
          email: email,
          date: new Date().toISOString(),
        }),
      })
        .then((response) => {
          if (!response.message=="OK") {
            setEmailError("Er ging iets fout.. stuur ons een mail");
          } else {
            setSubmitted(true);
          }
        })
        .catch((e) => {
          setEmailError("Er ging iets fout.. stuur ons een mail");
        });
    }
  };

  useEffect(() => {
    /* 
    reason to set it here is for consistency and not overcomplicating things.
    It might seem to make more sense to generate the UUID in the first form, but you want to be able to keep the UUID once you want to change data from a previous form
    */
    setAnonId(v4());
  }, []);
  if (submitted) {
    return (
      <div className="flex flex-col sm:flex-row max-w items-center w-full sm:w-auto sm:gap-4">
        <h3 className="whitespace-nowrap text-2xl font-medium leading-relaxed text-black-600 mr-4">
          We houden je op de hoogte!
        </h3>
      </div>
    );
  }
  return (
    <div className="flex flex-col sm:flex-row max-w items-center w-full sm:w-auto sm:gap-4">
      <h3 className="whitespace-nowrap text-2xl font-medium leading-relaxed text-black-600 mr-4">
        Blijf op de hoogte
      </h3>
      <div className="flex-row">
        <input
          type="email"
          placeholder="Jouw e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-3 border flex-grow border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
          required
        />
        <p className="text-red-500">{emailError}</p>
      </div>
      <ButtonOutline onClick={postAnswersToBackend} className={"mt-2 sm:mt-0"}>
        Abonneer
      </ButtonOutline>
    </div>
  );
};

export default Newsletter;

// import React, { useState } from "react";
// import ButtonOutline from "./misc/ButtonOutline";

// const Newsletter = () => {
//   const [email, setEmail] = useState("");

//   return (
//     <div className="flex flex-col sm:flex-row max-w-md sm:max-w-lg items-center sm:items-start gap-3 sm:gap-4 w-full">
//       {/* Titel */}
//       <h3 className="whitespace-nowrap text-lg sm:text-2xl font-medium text-black-600 text-center sm:text-left">
//         Blijf op de hoogte
//       </h3>

//       {/* Input veld */}
//       <input
//         type="email"
//         placeholder="Jouw e-mail"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//         className="w-full sm:w-auto flex-grow p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
//         required
//       />

//       {/* Abonneer button */}
//       <ButtonOutline className="w-full sm:w-auto">Abonneer</ButtonOutline>
//     </div>
//   );
// };

// export default Newsletter;
