import __layout from 'nextra-theme-docs'
import __themeConfig from '/Users/jacob/Desktop/emind/app_SJP/frontpage/theme.config.jsx'


import 'nextra-theme-docs/style.css'
import "../styles/tailwind.css";
import "../styles/slick.css";
import "../styles/AnnotationLayer.css"; // https://github.com/wojtekmaj/react-pdf/issues/1543#issuecomment-1632593763
import "../styles/TextLayer.css"; // https://github.com/wojtekmaj/react-pdf/issues/1292#issuecomment-1387296281
import { RoleProvider } from "../components/docs/RoleContext";

// Polyfill Promise.withResolvers if it doesn't exist
if (typeof Promise.withResolvers === "undefined") {
  if (typeof window !== "undefined") {
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    window.Promise.withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
  } else if (typeof global !== "undefined") {
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    global.Promise.withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
  }
}

function MyApp({ Component, pageProps }) {
  return (
    <RoleProvider>
      <Component {...pageProps} />
    </RoleProvider>
  );
}

export default MyApp;


const __nextra_internal__ = globalThis[Symbol.for('__nextra_internal__')] ||= Object.create(null)
__nextra_internal__.context ||= Object.create(null)
__nextra_internal__.Layout = __layout
__nextra_internal__.themeConfig = __themeConfig