import Footer from "./components/Layout/Footer";
import { useConfig } from "nextra-theme-docs";

export default {
  // head: (
  //   <>
  //     <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //     <meta property="og:title" content="Sipi" />
  //     <meta property="og:description" content="Het digitaal signaleringsplan" />
  //   </>
  // ),
  head: function useHead() {
    const config = useConfig();
    const favicons = [
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/favicon/apple-touch-icon.png',
      },
      // {
      //   rel: 'mask-icon',
      //   href: '/favicon/safari-pinned-tab.svg',
      //   color: '#F59A9A',
      // },
      {
        rel: 'icon',
        href: '/favicon/favicon.ico',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/favicon/favicon-16x16.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/favicon/favicon-32x32.png',
      },
      // {
      //   rel: 'icon',
      //   type: 'image/png',
      //   sizes: '96x96',
      //   href: '/favicon/favicon-96x96.png',
      // },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '192x192',
        href: '/favicon/android-chrome-192x192.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '512x512',
        href: '/favicon/android-chrome-512x512.png',
      },
      {
        rel: 'manifest',
        href: '/site.webmanifest',
      },
    ];
    const description =
      config.frontMatter.description ||
      "Documentatie rond het signaleringsplan";
    const image = config.frontMatter.image;
    const title = `${config.title} | Sipi`;
    return (
      <>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        {/* Favicons, meta */}
        {/* <link rel="apple-touch-icon" sizes="180x180" href="/favicon.ico" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon.ico"
        />
        <link rel="mask-icon" href="/favicon.ico" color="#000000" /> */}
        {favicons.map((linkProps) => (
          <link key={linkProps.href} {...linkProps} />
        ))}
        {/* Windows 8 app icon */}
        <meta name='msapplication-TileColor' content='#F53838' />
        <meta
          name='msapplication-TileImage'
          content='/favicon/android-chrome-192x192.png'
        />
        {/* Accent color on supported browser */}
        <meta name='theme-color' content='#F53838' />
        <meta name="msapplication-TileColor" content="#fff" />
        <meta httpEquiv="Content-Language" content="vi" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image" content={image} />
        <meta name="apple-mobile-web-app-title" content="Sipi docs" />
      </>
    );
  },
  footer: {
    component: (
      <Footer />
      // <span>
      //   © {new Date().getFullYear()} - eMindBrowser
      //   <br />
      //   {/* <a href="https://sipi.care" target="_blank">
      //     Sipi
      //   </a> */}
      // </span>
    ),
  },
  logo: <span>Terug naar Sipi</span>,
  titleSuffix: " – Handleiding",
  project: {
    //   link: 'https://github.com/shuding/nextra'
  },
  editLink: {
    component: null,
  },
  feedback: {
    content: "Vraag? Geef ons feedback",
    useLink: () => "mailto:contact@sipi.care",
  },
  darkMode: false,
  // i18n: [{ locale: "nl", name: "Dutch" }],
  toc: {
    backToTop: true,
    title: "Op deze pagina",
  },
  search: {
    emptyResult: "Geen gevonden resultaten.",
    loading: "Laden ..",
    error: "Error!",
    placeholder: "Zoek in documentatie",
  },
};
